import { css } from "@emotion/core";

import { theme } from "./theme";

export const globalStyles = css`
  * {
    box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
    font-size: 16px; /* Careful, all font-sizes are rem, based on this. */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
  :focus:not(:focus-visible) {
    outline: none;
  }

  [data-reach-dialog-overlay],
  [data-reach-combobox-popover] {
    z-index: 2;
  }

  [data-reach-combobox-list] {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 4px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.shadowBlue};
    border-radius: 4px;
  }
  a:link,
  a:visited {
    color: ${theme.colors.primary};
    text-decoration: none;
  }
  a:hover,
  a:target {
    text-decoration: underline;
  }

  .react-daterange-picker__wrapper {
    border: thin solid ${theme.colors.border};
    padding: 0.5rem;
    border-radius: 4px;
    background: white;
  }

  ul.pagination {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
  }

  ul.pagination li a {
    display: block;
    padding: 2px 8px;
    background: white;
    margin: 0 2px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0 1px 2px #0002;
    text-transform: capitalize;
    cursor: pointer;
    text-decoration: none;
  }

  ul.pagination li a:hover,
  ul.pagination li a:focus {
    box-shadow: 0 1px 2px #0002, 0 0 0 2px ${theme.colors.primary} inset;
  }

  ul.pagination li.active a {
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
  }

  /*
   *  react-pdf is weird and adds a message to the page when the pdf is loading.
   *  https://github.com/wojtekmaj/react-pdf/issues/321
   */
  .react-pdf__message--error {
    display: none;
  }
`;
