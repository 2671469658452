import { gql } from "@apollo/client";

export const getCompaniesQuery = gql`
  query getCompanies {
    companies {
      id
      name
    }
  }
`;
