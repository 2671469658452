export const theme = {
  space: 16,
  headerHeight: 48,
  colors: {
    text: "#495572",
    lighterText: "#98a8b7",
    primary: "#235c85",
    green: "#78aa00",
    secondaryGreen: "#43680d",
    darkGreen: "#2c4706",
    shadowBlue: "#253b52",
    lightBorder: "#e9eff7",
    border: "#adb6cd",
    lightBackground: "#f6f9fc",
    white: "white",
    textH2: "#495572",
    lightText: "#547A8C",
    warning: "#ff6a14",
    success: "#0ac24e",
    cardHeader: "#fafcfd",
    danger: "#c00"
  },
  palette: [
    "#1499CE",
    "#7C246F",
    "#EAD63F",
    "#343972",
    "#ED5B17",
    "#009691",
    "#1D6199",
    "#D31F1F",
    "#AD329C",
    "#006865"
  ]
};

export type Theme = typeof theme;
