import React, { FC, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  FiClock,
  FiHome,
  FiSearch,
  FiPlusCircle,
  FiCheckSquare,
  FiBriefcase,
  FiLogOut,
  FiActivity,
  FiUsers,
  FiUserCheck,
  FiUserPlus,
  FiEdit3,
  FiUserX,
  FiCalendar,
  FiDollarSign,
  FiBarChart,
  FiBook
} from "react-icons/fi";
import { FaRegNewspaper } from "react-icons/fa";

import { Container } from "./Sidenav.styled";
import { SidenavHeader } from "./SidenavHeader";
import { SidenavItem } from "./SidenavItem";
import { Avatar } from "./Avatar";
import { SidenavGroup } from "./SidenavGroup";
import { CurrentUserContext } from "../contexts/CurrentUser";
import { ModalsContext } from "../contexts/ModalsContext";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const iconSize = 16;
export const Sidenav: FC = () => {
  const { pathname, search } = useLocation();
  const currentUser = useContext(CurrentUserContext);
  const { openModal } = useContext(ModalsContext);

  const isInternal = currentUser?.role !== "reviewer";
  const isAdmin = currentUser?.role === "admin";
  const { logout } = useAuth0();

  return (
    <Container>
      <Link to="/">
        <SidenavItem icon={<FiHome size={iconSize} />}>G2i Platform</SidenavItem>
      </Link>
      <SidenavHeader>
        {currentUser && <Avatar name={currentUser.displayName || currentUser.username || ""} showName />}
      </SidenavHeader>
      <SidenavGroup title="Review Crew">
        <Link to="/my-assessments">
          <SidenavItem active={pathname.includes("my-assessments")} icon={<FiEdit3 size={iconSize} />}>
            My Assessments
          </SidenavItem>
        </Link>
      </SidenavGroup>
      {isInternal && (
        <>
          <SidenavGroup title="Developer Success">
            <SidenavItem icon={<FiSearch size={iconSize} />} onClick={() => openModal("Search Developers")}>
              Search Developers
            </SidenavItem>
            <Link to="/resume-search">
              <SidenavItem icon={<FiBook size={iconSize} />} active={pathname === "/resume-search"}>
                Search Resume
              </SidenavItem>
            </Link>
            <SidenavItem icon={<FiPlusCircle size={iconSize} />} onClick={() => openModal("Add Developer")}>
              Add New
            </SidenavItem>
            <Link to="/applicants">
              <SidenavItem icon={<FiUsers size={iconSize} />} active={pathname.includes("/applicants")}>
                Applicants
              </SidenavItem>
            </Link>
            <Link to="/vetting">
              <SidenavItem icon={<FiUserCheck size={iconSize} />} active={pathname === "/vetting"}>
                Vetting
              </SidenavItem>
            </Link>
            <Link to="/developers?stage=MEMBER&status=">
              <SidenavItem icon={<FiUserPlus size={iconSize} />} active={search.includes("stage=MEMBER")}>
                Members
              </SidenavItem>
            </Link>
            <Link to="/developers?stage=STANDBY&status=">
              <SidenavItem icon={<FiUserX size={iconSize} />} active={search.includes("stage=STANDBY")}>
                Stand By
              </SidenavItem>
            </Link>
            <Link to="/check-in/timeline">
              <SidenavItem active={pathname.includes("/check-in/timeline")} icon={<FiActivity size={iconSize} />}>
                Check In Timeline
              </SidenavItem>
            </Link>
            <Link to="/metrics">
              <SidenavItem active={pathname.includes("/metrics")} icon={<FiActivity size={iconSize} />}>
                Metrics
              </SidenavItem>
            </Link>
            <Link to="/kpis">
              <SidenavItem active={pathname.includes("/kpis")} icon={<FiActivity size={iconSize} />}>
                KPIs
              </SidenavItem>
            </Link>
            <Link to="/dstms">
              <SidenavItem icon={<FiUserCheck size={iconSize} />} active={pathname === "/dstms"}>
                DSTMs
              </SidenavItem>
            </Link>
            {isAdmin && (
              <Link to="/skills">
                <SidenavItem icon={<FiUserCheck size={iconSize} />} active={pathname === "/skills"}>
                  Skills
                </SidenavItem>
              </Link>
            )}
          </SidenavGroup>
          <SidenavGroup title="Fulfillment">
            <Link to="/jobs">
              <SidenavItem active={pathname.includes("/jobs")} icon={<FiBriefcase size={iconSize} />}>
                Jobs
              </SidenavItem>
            </Link>
            <Link to="/profiles">
              <SidenavItem active={pathname.includes("/profiles")} icon={<FaRegNewspaper size={iconSize} />}>
                Open Profiles
              </SidenavItem>
            </Link>
            <Link to="/meetings">
              <SidenavItem active={pathname === "/meetings"} icon={<FiCalendar size={iconSize} />}>
                Meetings
              </SidenavItem>
            </Link>
            <Link to="/companies">
              <SidenavItem active={pathname.includes("/companies")} icon={<FiDollarSign size={iconSize} />}>
                Companies
              </SidenavItem>
            </Link>
            <Link to="/check-in">
              <SidenavItem
                active={pathname.includes("/check-in") && !pathname.includes("/timeline")}
                icon={<FiClock size={iconSize} />}
              >
                Pending Check Ins
              </SidenavItem>
            </Link>
          </SidenavGroup>
          <SidenavGroup title="Vetting">
            <Link to="/vetting-overview">
              <SidenavItem icon={<FiBarChart size={iconSize} />} active={pathname.includes("/vetting-overview")}>
                Overview
              </SidenavItem>
            </Link>
            <Link to="/assessments">
              <SidenavItem icon={<FiCheckSquare size={iconSize} />} active={pathname.includes("/assessments")}>
                Assessments
              </SidenavItem>
            </Link>
          </SidenavGroup>
          {isAdmin && (
            <SidenavGroup title="Clients">
              <Link to="/clients">
                <SidenavItem icon={<FiUserPlus size={iconSize} />} active={pathname.includes("/clients")}>
                  Users
                </SidenavItem>
              </Link>
            </SidenavGroup>
          )}
        </>
      )}
      <SidenavGroup title="You">
        <SidenavItem
          icon={<FiLogOut size={iconSize} />}
          onClick={() => {
            logout({ logoutParams: { returnTo: window.location.origin } });
          }}
        >
          Logout
        </SidenavItem>
      </SidenavGroup>
    </Container>
  );
};
